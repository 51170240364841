import './projects.css'
import React from 'react'

const data = [
  {
    id: 1,
    title: 'sweater weather',
    description: "a back-end solution for users to plan a road trip and view weather at a desired destination. retrieves current, 5-day, and hourly weather forecasts for a city using mapquest geocoding and openweather one call apis.",
    github: 'https://github.com/khoifishpond/sweater-weather'
  },
  {
    id: 2,
    title: 'viewing party',
    description: "a web application allowing users to create accounts, log in, explore movie options, and send out invations to their friends for a viewing party. consumes the movie db api for film details, cast information, and reviews.",
    github: 'https://github.com/khoifishpond/viewing_party'
  },
  {
    id: 2,
    title: 'little esty shop',
    description: "an e-commerce platform for over 1,000 merchants and admins to manage inventory and fulfill customer invoices. uses activerecord to write queries to gather and manipulate data from a postgresql database.",
    github: 'https://github.com/khoifishpond/little-esty-shop'
  }
]

const Projects = () => {
  return (
    <>
      <div className="container projects-container">
        <div className="projects-greeting">
          <h5>some of my work</h5>
          <h1>projects</h1>
        </div>
        <div className="projects">
          {data.map(({id, description, title, github}) => {
              return (
                <a
                  href={github}
                  target='_blank'
                  rel='noreferrer'
                >
                  <article key={id} className='project'>
                    <h4>{title}</h4>
                    <small>{description}</small>
                  </article>
                </a>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default Projects