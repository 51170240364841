import './about.css'
import React from 'react'
import { BsBook, BsFolder } from 'react-icons/bs'
import { GiDiploma } from 'react-icons/gi'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
      <div className="container about-container">
        <div className="about-greeting">
          <h5>get to know</h5>
          <h1>about me</h1>
        </div>
        <div className="about-me">
          <div className="cards">
            <article className="card">
              <BsBook className='card-icon'/>
              <h4>experience</h4>
              <small>2+ years</small>
            </article>
            <article className="card">
              <BsFolder className='card-icon'/>
              <h4>projects</h4>
              <small>20+ completed</small>
            </article>
            <article className="card">
              <GiDiploma className='card-icon'/>
              <h4>csu long beach</h4>
              <small>b.s. - management information systems</small>
            </article>
          </div>
          <p>
            <small>
              i recently made the decision to transition out of the health industry
              where i was a service account manager at optum, a pharmacy benefits
              manager. i have since graduated turing's school of software & design
              with a focus on backend engineering, providing me the pleasure of meeting
              so many wonderful developers across the industry within the country.
              with a background in ruby on rails, python, postgresql, and react, i'm
              so excited to see where my journey takes me. i hope that involves you!
            </small>
          </p>
          <div className="action">
            <Link to='/contact' className='btn btn-primary'>
              let's talk!
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default About