import './home.css'
import React from 'react'
import { Link } from 'react-router-dom'
import Portrait from '../../images/me.jpg'
import Resume from '../../images/resume.pdf'

const Home = () => {
  return (
    <>
      <div className="container home-container">
        <div className='home-welcome'>
          <h5>hi hello, i'm</h5>
          <h1>khoi</h1>
          <img src={Portrait} alt="Hi hello!" />
          <h5>software engineer</h5>
          <div className="action">
            <a href={Resume} download className="btn">resume</a>
            <Link to='/contact' className='btn btn-primary'>contact</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home