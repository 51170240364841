import './experience.css'
import {SiHtml5, SiCss3, SiJavascript, SiReact, SiRubyonrails, SiPython, SiDjango, SiPostgresql} from 'react-icons/si'
import React from 'react'

const Experience = () => {
  return (
    <>
      <div className="container exp-container">
        <div className="exp-greeting">
          <h5>leveling up with</h5>
          <h1>experience</h1>
        </div>
        <div className="exp">
          <div className="skills">
            <div className="frontend">
              <h3>frontend development</h3>
              <div className="skills-content">
                <article className="skill">
                  <SiHtml5 className='skill-icon' />
                  <h4>html</h4>
                </article>
                <article className="skill">
                  <SiCss3 className='skill-icon' />
                  <h4>css</h4>
                </article>
                <article className="skill">
                  <SiJavascript className='skill-icon' />
                  <h4>javascript</h4>
                </article>
                <article className="skill">
                  <SiReact className='skill-icon' />
                  <h4>react</h4>
                </article>
              </div>
            </div>
            <div className="backend">
              <h3>backend development</h3>
              <div className="skills-content">
                <article className="skill">
                  <SiPython className='skill-icon' />
                  <h4>python</h4>
                </article>
                <article className="skill">
                  <SiRubyonrails className='skill-icon' />
                  <h4>ruby on rails</h4>
                </article>
                <article className="skill">
                  <SiDjango className='skill-icon' />
                  <h4>django</h4>
                </article>
                <article className="skill">
                  <SiPostgresql className='skill-icon' />
                  <h4>postgresql</h4>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Experience