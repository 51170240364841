import './contact.css'
import { React, useRef } from 'react'
import { BsLinkedin, BsGithub, BsTwitter, BsInstagram } from 'react-icons/bs'
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify'

const Contact = () => {
  const notify = () => {
    toast.success('Email sent', {
      position: toast.POSITION.TOP_CENTER
    });
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_qorpcon',
      'template_r7z2xxg',
      form.current,
      'Yne9rpwhcW5f1l-dN'
    );

    e.target.reset();
  }
  
  return (
    <>
      <div className="container contact-container">
        <div className="contact-greeting">
          <h5>get in touch</h5>
          <h1>contact</h1>
        </div>
        <div className="my-contact-info">
          <div className="contact-email">
            <form ref={form} onSubmit={sendEmail}>
              <input type='text' name='name' placeholder='Full Name' required />
              <input type="email" name='email' placeholder='Email' required />
              <textarea name="message" id="message" rows='7' placeholder='Message' required />
              <button type='submit' className='btn btn-primary' onClick={notify}>send</button>
            </form>
          </div>
        </div>
        <div className="contact-socials">
          <div className="social">
            <a
              href="http://linkedin.com/in/khoifishpond"
              target='_blank'
              rel='noreferrer'
            >
              <BsLinkedin className='social-icon'/>
            </a>
          </div>
          <div className="social">
            <a
              href="http://github.com/khoifishpond"
              target='_blank'
              rel='noreferrer'
            >
              <BsGithub className='social-icon'/>
            </a>
          </div>
          <div className="social">
            <a
              href="http://instagram.com/khoifishpond"
              target='_blank'
              rel='noreferrer'
            >
              <BsInstagram className='social-icon'/>
            </a>
          </div>
          <div className="social">
            <a
              href="http://twitter.com/khoifishpond"
              target='_blank'
              rel='noreferrer'
            >
              <BsTwitter className='social-icon'/>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact