import './nav.css'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { AiOutlineHome, AiOutlineMail } from 'react-icons/ai'
import { SiAboutdotme } from 'react-icons/si'
import { BsCode, BsCodeSlash } from 'react-icons/bs'

const Nav = () => {
  return (
    <>
      <nav>
        <NavLink
          exact="true"
          activeClassName="active"
          className="link navbar-home"
          to="/"
        >
          <AiOutlineHome />
        </NavLink>
        <NavLink
          exact="true"
          activeClassName="active"
          className="link navbar-about"
          to="/about"
        >
          <SiAboutdotme />
        </NavLink>
        <NavLink
          exact="true"
          activeClassName="active"
          className="link navbar-experience"
          to="/experience"
        >
          <BsCode />
        </NavLink>
        <NavLink
          exact="true"
          activeClassName="active"
          className="link navbar-projects"
          to="/projects"
        >
          <BsCodeSlash />
        </NavLink>
        <NavLink
          exact="true"
          activeClassName="active"
          className="link navbar-contact"
          to="/contact"
        >
          <AiOutlineMail />
        </NavLink> 
      </nav>
    </>
  )
}

export default Nav